import { FooterWrapper, StyledLink } from ".././AppStyles";

export default function Footer() {
  return (
    <FooterWrapper>
      <p>© 2023 Site by</p>
      <StyledLink href="https://www.elizalands.net/"> Eliza Lands</StyledLink>
    </FooterWrapper>
  );
}
