import { useState, useEffect } from "react";
import axios from "axios";

import PokemonCards from "./components/PokemonCards";
import Header from "./components/Header";
import { PokemonStats } from "./interfaces";
import Footer from "./components/Footer";

function App() {
  const [pokemonData, setPokemonData] = useState<PokemonStats[]>([]);
  const [activeCircleSwitch, setActiveCircleSwitch] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getPokemonData = async () => {
      setIsLoading(true);
      const pokemonUrl = "https://pokeapi.co/api/v2/pokemon?limit=100";
      try {
        const res = await axios.get(pokemonUrl);
        const alphabetData = res.data.results.sort(
          (a: PokemonStats, b: PokemonStats) => a.name.localeCompare(b.name)
        );
        alphabetData.forEach(async (pokemon: PokemonStats) => {
          const poke = await axios.get(
            `https://pokeapi.co/api/v2/pokemon/${pokemon.name}`
          );
          setPokemonData((p) => [...p, poke.data]);
        });
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    getPokemonData();
  }, []);

  return (
    <>
      <Header
        activeCircleSwitch={activeCircleSwitch}
        setActiveCircleSwitch={setActiveCircleSwitch}
        pokemonData={pokemonData}
        isLoading={isLoading}
      />

      <PokemonCards pokemonData={pokemonData} />

      <Footer />
    </>
  );
}

export default App;
